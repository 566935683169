@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Black.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Bold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-BoldItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Medium.otf") format("opentype");
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-MediumItalic.otf") format("opentype");
  font-weight: 450;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-LightItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Book.otf") format("opentype");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-BookItalic.otf") format("opentype");
  font-weight: 350;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/CircularStd-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}


* {
  font-family: 'Circular', sans-serif !important;
}

body, html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  height: auto;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
